const products = [
  {
    id: 1,
    title: "Gaming Beast",
    description: "Perfect for high-end gaming.",
    image: "/path-to-image1.jpg",
    price: 1500,
    category: "Gaming",
    CPU: "Intel i9 9900K",
    RAM: "32GB DDR4 3200MHz",
    GPU: "NVIDIA RTX 3080",
    MB: "MSI Z490 TOMAHAWK",
    storage: ["1TB SSD"],
    storageType: ["NVMe"],
    PSU: "Corsair RM850x",
    Case: "NZXT H510",
    Cooling: "NZXT Kraken X63",
    fullDescription: "A detailed description about the Gaming Beast...",
  },
  {
    id: 2,
    title: "Developer Workhorse",
    description: "Ideal for developers.",
    image: "/path-to-image2.jpg",
    price: 1200,
    category: "Development",
    CPU: "Ryzen 7 3700X",
    RAM: "32GB DDR4 3000MHz",
    GPU: "NVIDIA RTX 3060 Ti",
    MB: "ASUS PRIME B550-PLUS",
    storage: ["500GB SSD", "1TB HDD"],
    storageType: ["NVMe", "7200RPM"],
    PSU: "Corsair CX600",
    Case: "Corsair 4000D",
    Cooling: "Cooler Master Hyper 212",
    fullDescription: "A detailed description about the Developer Workhorse...",
  },
  {
    id: 3,
    title: "Office Efficient",
    description: "Best for everyday tasks.",
    image: "/path-to-image3.jpg",
    price: 700,
    category: "Business",
    CPU: "Intel i5 10400",
    RAM: "16GB DDR4 2666MHz",
    GPU: "Integrated Graphics",
    MB: "Gigabyte B460M DS3H",
    storage: ["256GB SSD"],
    storageType: ["NVMe"],
    PSU: "EVGA 500 W1",
    Case: "Fractal Design Core 1100",
    Cooling: "Stock Cooler",
    fullDescription: "A detailed description about the Office Efficient...",
  },
  {
    id: 4,
    title: "Digital Art Pro",
    description: "Designed for graphic designers.",
    image: "/path-to-image4.jpg",
    price: 1300,
    category: "Work",
    CPU: "Ryzen 7 3700X",
    RAM: "32GB DDR4 3200MHz",
    GPU: "NVIDIA RTX 3070",
    MB: "MSI MPG B550 GAMING PLUS",
    storage: ["1TB SSD"],
    storageType: ["NVMe"],
    PSU: "Seasonic S12III",
    Case: "Phanteks Eclipse P300A",
    Cooling: "Be Quiet! Pure Rock 2",
    fullDescription: "A detailed description about the Digital Art Pro...",
  },
  {
    id: 5,
    title: "Multi-Task Master",
    description: "Excellent for multitasking and productivity.",
    image: "/path-to-image5.jpg",
    price: 900,
    category: "Work",
    CPU: "Ryzen 5 3600",
    RAM: "16GB DDR4 3000MHz",
    GPU: "AMD RX 570",
    MB: "Gigabyte B450 AORUS M",
    storage: ["500GB SSD", "500GB HDD"],
    storageType: ["NVMe", "7200RPM"],
    PSU: "Corsair CV450",
    Case: "Cooler Master MasterBox Q300L",
    Cooling: "Stock Cooler",
    fullDescription: "A detailed description about the Multi-Task Master...",
  },
  {
    id: 6,
    title: "Gaming Elite",
    description: "High frame rates and ultra settings.",
    image: "/path-to-image6.jpg",
    price: 2000,
    category: "Gaming",
    CPU: "Intel i7 11700K",
    RAM: "32GB DDR4 3600MHz",
    GPU: "NVIDIA RTX 3090",
    MB: "ASUS ROG STRIX Z590-E",
    storage: ["2TB SSD"],
    storageType: ["NVMe"],
    PSU: "EVGA 850 G5",
    Case: "Corsair 4000D Airflow",
    Cooling: "Corsair H115i",
    fullDescription: "A detailed description about the Gaming Elite..."
  },
  {
    id: 7,
    title: "Dev Pro",
    description: "Built for heavy development and multitasking.",
    image: "/path-to-image7.jpg",
    price: 2100,
    category: "Development",
    CPU: "AMD Ryzen 9 5950X",
    RAM: "64GB DDR4 4000MHz",
    GPU: "NVIDIA RTX 3070 Ti",
    MB: "MSI MPG B550 GAMING EDGE",
    storage: ["1TB SSD", "2TB HDD"],
    storageType: ["NVMe", "SATA"],
    PSU: "Seasonic FOCUS Plus",
    Case: "Lian Li O11D",
    Cooling: "NZXT Kraken Z63",
    fullDescription: "A detailed description about Dev Pro..."
  },
  {
    id: 8,
    title: "Business Pro",
    description: "Fast and secure for business needs.",
    image: "/path-to-image8.jpg",
    price: 1300,
    category: "Business",
    CPU: "Intel i5 11600K",
    RAM: "16GB DDR4 2666MHz",
    GPU: "Intel UHD Graphics 750",
    MB: "Gigabyte B460M DS3H",
    storage: ["512GB SSD"],
    storageType: ["NVMe"],
    PSU: "Corsair CV450",
    Case: "Fractal Design Define Mini",
    Cooling: "Cooler Master Hyper 212",
    fullDescription: "A detailed description about Business Pro..."
  },
  {
    id: 9,
    title: "WorkStation",
    description: "Versatile and powerful for any work scenario.",
    image: "/path-to-image9.jpg",
    price: 1600,
    category: "Work",
    CPU: "AMD Ryzen 7 5800X",
    RAM: "32GB DDR4 3200MHz",
    GPU: "NVIDIA Quadro P2200",
    MB: "ASUS Pro WS X570-ACE",
    storage: ["1TB SSD"],
    storageType: ["NVMe"],
    PSU: "be quiet! Straight Power 11",
    Case: "NZXT H710",
    Cooling: "be quiet! Dark Rock Pro 4",
    fullDescription: "A detailed description about WorkStation..."
  },
  {
    id: 10,
    title: "Economy Gaming",
    description: "Affordable yet capable gaming setup.",
    image: "/path-to-image10.jpg",
    price: 800,
    category: "Gaming",
    CPU: "AMD Ryzen 5 5600G",
    RAM: "16GB DDR4 3000MHz",
    GPU: "AMD Radeon Vega Graphics",
    MB: "ASRock B450M PRO4",
    storage: ["512GB SSD"],
    storageType: ["NVMe"],
    PSU: "EVGA 600 W1",
    Case: "Cooler Master MasterBox Q300L",
    Cooling: "Stock Cooler",
    fullDescription: "A detailed description about Economy Gaming..."
  }
];

export default products;
