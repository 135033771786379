// src/pages/Services.js

import React, { useState } from "react";
import { Button, Container, Typography } from "@mui/material";
import ServiceInfo from "../components/ServiceInfo";

function Services() {
  const [userType, setUserType] = useState(null);
  const [specificType, setSpecificType] = useState(null);

  const handleUserTypeSelection = (type) => {
    setUserType(type);
    setSpecificType(null);
  };

  const handleSpecificTypeSelection = (type) => {
    setSpecificType(type);
  };

  return (
    <Container>
      {/* Condition for when there's no userType */}
      {!userType && (
        <>
          <Typography variant="h4" gutterBottom>
            Who are you?
          </Typography>
          <Button
            variant="outlined"
            onClick={() => handleUserTypeSelection("consumer")}
          >
            Consumer
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleUserTypeSelection("business")}
          >
            Business
          </Button>
        </>
      )}

      {/* Condition for when userType is 'consumer' and no specificType */}
      {userType === "consumer" && !specificType && (
        <>
          <Typography variant="h4" gutterBottom>
            What kind of consumer are you?
          </Typography>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("Gamer")}
          >
            Gamer
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("Developer")}
          >
            Developer
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("Professional")}
          >
            Professional
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("viewAllConsumer")}
          >
            View All Services
          </Button>
        </>
      )}

      {/* Condition for when userType is 'business' and no specificType */}
      {userType === "business" && !specificType && (
        <>
          <Typography variant="h4" gutterBottom>
            What kind of business are you?
          </Typography>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("DeveloperFirm")}
          >
            Developer Firm
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("MultimediaFirm")}
          >
            Multimedia Firm
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("GamingCafe")}
          >
            Gaming Cafe
          </Button>
          <Button
            variant="outlined"
            onClick={() => handleSpecificTypeSelection("viewAllBusiness")}
          >
            View All Services
          </Button>
        </>
      )}

      {/* Condition for rendering the ServiceInfo component */}
      {userType && specificType && (
        <ServiceInfo userType={userType} specificType={specificType} />
      )}
    </Container>
  );
}

export default Services;
