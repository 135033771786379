import React from "react";
import { Container, Typography, Box, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Slideshow from "../components/Slideshow.js";
import Reviews from "../components/Reviews.js";
//Homepage
function Home() {
  return (
    <Grid
      container
      spacing={0}
      sx={{ display: "flex", alignItems: "stretch"}}
    >
      {/* Left Content */}
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{
          background: "#050a1a",
          color: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="h1" align="left">
            HorizonTech
          </Typography>
          <Typography variant="body1" fontSize={29} align="left" paddingBottom={3}>
            Expertly crafted with top-tier components to deliver unbeatable
            performance. Dive into immersive gameplay, seamless multitasking,
            and lightning-fast load times. Tailored to your gaming needs,
            experience the next level of power and precision. Elevate your game
          </Typography>
        </Container>
      </Grid>

      {/* Right Image */}
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          component="img"
          alt="HorizonTech Banner Image"
          src="/assets/images/hero-image.jpg"
          sx={{
            height: { xs: "233px", md: "600px" },
            objectFit: "cover",
            width: "100%",
          }}
        />
      </Grid>

      {/*Why buy section*/}
      <Grid
        item
        xs={12}
        md={12}
        align="left"
        sx={{
          position: "relative",
          overflow: "hidden",
          background: "#050a1a",
          color: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          
            width: "100%",
            position: "relative",
            zIndex: 2,
          }}
        >
          <Container maxWidth="xl" sx={{ marginTop: 0, marginBottom: 1, paddingY:2}}>
            <Typography variant="h2" gutterBottom>
              Why Choose Us?
            </Typography>
            <Typography variant="h4" fontSize={25}>
              At HorizonTech, we merge passion with innovation to deliver
              exceptional custom PCs. Our tech-savvy team ensures each machine
              meets the highest standards, tailored to your unique needs. From
              avid gamers to professional creators, we've got you covered. By
              only partnering with top-tier component suppliers, we guarantee
              performance and longevity. Our commitment doesn't end at the sale;
              expect unmatched post-purchase support. When you choose
              HorizonTech, you're not just getting a computer – you're investing
              in a reliable tech partnership and joining a community dedicated
              to excellence.
            </Typography>
            <Button
              variant="text"
              color="primary"
              sx={{ marginTop: 0, color: "#BDD0F9", px: 0 }}
            >
              Show More
            </Button>
          </Container>
        </Box>
      </Grid>

      {/*View Builds*/}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ position: "relative", overflow: "hidden" }}
      >
        <Link
          to="/shop"
          style={{
            textDecoration: "none",
            display: "block",
            height: { xs: "233px", md: "400px" },
          }}
        >
          <Box
            component="img"
            alt="HorizonTech Banner Image"
            src="/assets/images/Gaming-PC.jpg"
            sx={{
              height: { xs: "233px", md: "400px" },
              objectFit: "cover",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          />

          <Box
            sx={{
              height: { xs: "233px", md: "400px" },
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "rgba(36, 112, 242,0.5)", // Semi-transparent blue
              zIndex: 1,
            }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: { xs: "233px", md: "400px" },
              width: "100%",
              position: "relative",
              zIndex: 2,
            }}
          >
            <Typography variant="h3" color="White">
              View Builds
            </Typography>
          </Box>
        </Link>
      </Grid>

      {/*Gallery*/}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ position: "relative", overflow: "hidden" }}
      >
        <Link
          to="/about"
          style={{
            textDecoration: "none",
            display: "block",
            height: { xs: "233px", md: "400px" },
          }}
        >
          <Box
            component="img"
            alt="HorizonTech Banner Image"
            src="/assets/images/Digital-Art-PC.jpg"
            sx={{
              height: { xs: "233px", md: "400px" },
              objectFit: "cover",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 0,
            }}
          />

          <Box
            sx={{
              height: { xs: "233px", md: "400px" },
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "rgba(36, 112, 242,0.5)", // Semi-transparent blue
              zIndex: 1,
            }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: { xs: "233px", md: "400px" },
              width: "100%",
              position: "relative",
              zIndex: 2,
            }}
          >
            <Typography variant="h3" color="White">
              Gallery
            </Typography>
          </Box>
        </Link>
      </Grid>
      {/*Slideshow*/}
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        sx={{ position: "relative", overflow: "hidden" }}
      >
        <Slideshow />
      </Grid>

      {/*Reviews*/}
      <Grid
        item
        xs={12}
        md={12}
        lg={6}
        sx={{ position: "relative", overflow: "hidden",background: "#050a1a",color: "white"}}
      >
         <Typography variant="h2" gutterBottom margin="20px">
            Reviews
          </Typography>
        <Reviews />
      </Grid>
      
    </Grid>
  );
}

export default Home;
