// src/components/UnderConstruction.js

import React from "react";
import { Typography, Box } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

function UnderConstruction() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#050a1a",
      }}
    >
      <Box
        sx={{
          fontSize: "8rem",
          color: "#ffffff",
        }}
      >
        <SettingsIcon />
      </Box>
      <Typography variant="h6" color="primary.main">
        This page is currently under construction...
      </Typography>
    </Box>
  );
}

export default UnderConstruction;
