import React from "react";
import { Container, Typography, Box, Grid } from "@mui/material";

const About = () => {
  return (
    <Grid sx={{backgroundColor: "#050a1a"}} > 
    <Container maxWidth="xl">
      <Grid
        container
        spacing={0}
        sx={{paddingY: 2, display: "flex", alignItems: "stretch", color:"white" }}
      >
        <Grid item xs={12} md={12} lg={8} >
          <Typography variant="h2" gutterBottom>
            About HorizonTech
          </Typography>

          <Typography variant="h4" gutterBottom >
            Who We Are
          </Typography>
          <Typography paragraph fontSize={24}>
            HorizonTech is a small business based in Toronto, Canada. We
            specialize in building custom PCs for a variety of use cases,
            including gaming, music production, video/image editing, and more.
            We also offer a range of pre-built machines, as well as a selection
            of components and peripherals.
          </Typography>

          <Typography variant="h4" gutterBottom >
            Our Vision
          </Typography>
          <Typography paragraph fontSize={24}>
            We believe in the power of technology to transform lives. By
            crafting the perfect machine for each individual, we aim to enhance
            productivity, creativity, and leisure.
          </Typography>

          <Typography variant="h4" gutterBottom>
            Why Choose Us?
          </Typography>
          <Typography paragraph fontSize={24}>
            Our team of dedicated experts is committed to staying at the
            forefront of technology. We utilize the latest components, ensure
            rigorous quality checks, and offer post-purchase support to ensure
            you're satisfied with your machine for years to come. Our PCs aren't
            just built; they're crafted with passion.
          </Typography>

          <Typography variant="h4" gutterBottom>
            Custom Solutions
          </Typography>
          <Typography paragraph fontSize={24}>
            Whether you're into gaming, music production, video/image editing,
            or just need a powerful work machine, we provide a holistic
            approach, including custom OS installations and software setups.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 4,
          }}
        >
          <Box
            component="img"
            src="/assets/images/CN-Tower.jpg"
            alt="GTA"
            sx={{
              objectFit: "cover",
              width: "100%",
              borderRadius: "20px",
            }}
          />
        </Grid>
      </Grid>
    </Container>
    </Grid>
  );
};

export default About;
