import React from "react";
import products from "../products";
import { useParams } from "react-router-dom";

const ProductDetails = ({ product }) => {
  const { id } = useParams();
  const actualProduct = products.find((p) => p.id === parseInt(id));

  if (!actualProduct) {
    return <div>Product not found</div>;
  }

  return (
    <div className="actualProduct-details">
      <img src={actualProduct.image} alt={actualProduct.title} />
      <h1>{actualProduct.title}</h1>
      <p>{actualProduct.fullDescription}</p>
      <ul>
        <li>
          <strong>CPU:</strong> {actualProduct.CPU}
        </li>
        <li>
          <strong>RAM:</strong> {actualProduct.RAM}
        </li>
        <li>
          <strong>GPU:</strong> {actualProduct.GPU}
        </li>
        <li>
          <strong>Motherboard:</strong> {actualProduct.MB}
        </li>
        <li>
          <strong>Storage:</strong> {actualProduct.storage.join(", ")}
        </li>
        <li>
          <strong>Storage Type:</strong> {actualProduct.storageType.join(", ")}
        </li>
        <li>
          <strong>Power Supply:</strong> {actualProduct.PSU}
        </li>
        <li>
          <strong>Case:</strong> {actualProduct.Case}
        </li>
        <li>
          <strong>Cooling:</strong> {actualProduct.Cooling}
        </li>
      </ul>
      <span>Price: ${actualProduct.price}</span>
    </div>
  );
};

export default ProductDetails;
