import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, } from '@mui/material';

const testimonialsData = [
    {
        name: 'Harry',
        content: "Horizontech built me the ultimate gaming rig. It’s both whisper-quiet and lightning fast. Couldn't be happier with their expertise and attention to detail"
    },
    {
        name: 'Kian Dadkhah',
        content: "As a software developer, I need reliability and power. Horizontech delivered just that, with exceptional customer service to boot. Their team understands the tech needs of professionals."
    },
    {
        name: 'Sara',
        content: "I've been a PC enthusiast for years, but Horizontech brought something new to the table. Their unique and optimized builds have made me a loyal customer. Kudos to the team!",
    },
];

export default function Reviews () {
    const [activeIndex, setActiveIndex] = useState(0);
    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const fadeOutInterval = setInterval(() => {
            setOpacity(0);
        }, 5500);  // Starts fading out after 4 seconds

        const changeSlideInterval = setInterval(() => {
            setActiveIndex((prevActiveIndex) => (prevActiveIndex + 1) % testimonialsData.length);
            setOpacity(1);  // Immediately set opacity to 1 after changing the slide
        }, 6000);  // Change slide every 4.5 seconds

        return () => {
            clearInterval(fadeOutInterval);
            clearInterval(changeSlideInterval);
        };
    }, [activeIndex]);

    return (
        <Box style={{ margin: 'auto', transition: 'opacity 0.5s', opacity: opacity }}>
            
            <CardContent>
                <Typography variant="h4" gutterBottom>
                    "{testimonialsData[activeIndex].content}"
                </Typography>
                <Typography variant="subtitle1" align="right">
                    - {testimonialsData[activeIndex].name}
                </Typography>
            </CardContent>
        </Box>
    );
}