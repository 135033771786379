// src/pages/SystemBuilderPage.js

import React, { useState } from "react";
// import { Button, Container, Typography } from "@mui/material";
// import CurateBuild from "../components/CurateBuild";
// import DesignBuild from "../components/DesignBuild";
import UnderConstruction from "../components/UnderConstruction";

const SystemBuilderPage = () => {
  //   const [selectedOption, setSelectedOption] = useState("");

  //   const handleOptionClick = (option) => {
  //     setSelectedOption(option);
  //   };

  return (
    <UnderConstruction />
    //   <Container>
    //     <Typography variant="h2" align="center" gutterBottom>
    //       System Builder
    //     </Typography>
    //     {selectedOption ? (
    //       selectedOption === "Curate" ? (
    //         <CurateBuild />
    //       ) : (
    //         <DesignBuild />
    //       )
    //     ) : (
    //       <div style={{ textAlign: "center" }}>
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           onClick={() => handleOptionClick("Curate")}
    //         >
    //           Curate My Build
    //         </Button>
    //         <Button
    //           variant="contained"
    //           color="secondary"
    //           onClick={() => handleOptionClick("Design")}
    //         >
    //           Design My Build
    //         </Button>
    //       </div>
    //     )}
    //   </Container>
  );
};

export default SystemBuilderPage;
