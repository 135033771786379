import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   Typography,
//   Slider,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Container,
// } from "@mui/material";
// import CategoryCard from "../components/CategoryCard";
// import products from "../products";
// import ProductCard from "../components/ProductCard";
import UnderConstruction from "../components/UnderConstruction";

const Shop = () => {
  // const [filteredProducts, setFilteredProducts] = useState(products);
  // const [priceRange, setPriceRange] = useState([0, 5000]); // [min, max]
  // const [sortOption, setSortOption] = useState("default");

  // useEffect(() => {
  //   filterAndSortProducts();
  // }, [sortOption, priceRange]);

  // const handleCategorySelect = (category) => {
  //   if (category === "View All Systems") {
  //     setFilteredProducts(products);
  //   } else {
  //     const filtered = products.filter(
  //       (product) => product.category === category
  //     );
  //     setFilteredProducts(filtered);
  //   }
  // };

  // const filterAndSortProducts = () => {
  //   let currentProducts = products.filter(
  //     (product) =>
  //       product.price >= priceRange[0] && product.price <= priceRange[1]
  //   );

  //   switch (sortOption) {
  //     case "priceHighToLow":
  //       currentProducts.sort((a, b) => b.price - a.price);
  //       break;
  //     case "priceLowToHigh":
  //       currentProducts.sort((a, b) => a.price - b.price);
  //       break;
  //     case "alphabetical":
  //       currentProducts.sort((a, b) => a.name.localeCompare(b.name));
  //       break;
  //     case "newest":
  //       currentProducts.sort(
  //         (a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)
  //       );
  //       break;
  //     default:
  //       break;
  //   }

  //   setFilteredProducts(currentProducts);
  // };

  return (
    <UnderConstruction />
    // <div>
    //   <Container maxWidth="xl">
    //   {/* Category Cards */}
    //   <Grid container justifyContent="center" spacing={3}>
    //     {["Work", "Gaming", "Business", "Development", "View All Systems"].map(
    //       (category) => (
    //         <Grid item xs={12} sm={6} md={4} lg={3} key={category}>
    //           <CategoryCard
    //             title={category}
    //             onClick={() => handleCategorySelect(category)}
    //           />
    //         </Grid>
    //       )
    //     )}
    //   </Grid>

    //   {/* Price Range Slider */}
    //   <Typography gutterBottom>Price Range</Typography>
    //   <Slider
    //     value={priceRange}
    //     onChange={(event, newValue) => setPriceRange(newValue)}
    //     valueLabelDisplay="auto"
    //     max={5000}
    //     min={0}
    //   />

    //   {/* Sorting Dropdown */}
    //   <FormControl fullWidth variant="outlined" style={{ marginTop: "20px" }}>
    //     <InputLabel>Sort By</InputLabel>
    //     <Select
    //       value={sortOption}
    //       onChange={(e) => setSortOption(e.target.value)}
    //       label="Sort By"
    //     >
    //       <MenuItem value="default">Default</MenuItem>
    //       <MenuItem value="priceHighToLow">Price: High to Low</MenuItem>
    //       <MenuItem value="priceLowToHigh">Price: Low to High</MenuItem>
    //       <MenuItem value="alphabetical">Alphabetical</MenuItem>
    //       <MenuItem value="newest">Newest First</MenuItem>
    //     </Select>
    //   </FormControl>

    //   {/* Product Display */}
    //   <Grid
    //     container
    //     justifyContent="center"
    //     spacing={4}
    //     style={{ marginTop: "20px" }}
    //   >
    //     {filteredProducts.map((product) => (
    //       <Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
    //         <ProductCard product={product} />
    //       </Grid>
    //     ))}
    //   </Grid>
    //   </Container>
    // </div>
  );
};

export default Shop;
