// src/ServicesData.js

const servicesData = {
  Gamer: [
    { name: "Build", price: "$59" },
    { name: "Build w Pro Cable Management", price: "$69" },
    { name: "BIOS Setup", price: "FREE!" },
    { name: "Windows OS Installation", price: "FREE!" },
    { name: "AIO Watercooling", price: "FREE!" },
    { name: "Build w Soft-Tube Watercooling", price: "$139" },
    { name: "Build w Hard-Tube Watercooling", price: "$189" },
    { name: "Windows OS Cleaning & Optimization", price: "$19" },
    { name: "Drivers & BIOS Updates", price: "$12" },
    {
      name: "Repair, Service & Upgrade",
      price: "6 months FREE, Upgrade to 1 year for $49",
    },
  ],
  Developer: [
    { name: "Build", price: "$59" },
    { name: "Build w Pro Cable Management", price: "$69" },
    { name: "BIOS Setup", price: "FREE!" },
    { name: "Windows OS Installation", price: "FREE!" },
    { name: "Linux Installation/Dual Boot", price: "$49" },
    { name: "Windows OS Cleaning & Optimization", price: "$19" },
    {
      name: "Virtualization Setup VirtualBox/VMWare/Hyper-V/KVM",
      price: "$39",
    },
    { name: "Developer Environment Setup Package", price: "$119" },
    {
      name: "Repair, Service & Upgrade",
      price: "6 months FREE, Upgrade to 1 year for $49",
    },
  ],
  Professional: [
    { name: "Build", price: "$69" },
    { name: "Windows OS Installation", price: "FREE!" },
    { name: "Windows OS Cleaning & Optimization", price: "$19" },
    { name: "Additional Software Setup & Installation", price: "$19" },
    {
      name: "Repair, Service & Upgrade",
      price: "6 months FREE, Upgrade to 1 year for $49",
    },
  ],
  DeveloperFirm: [
    "Consulting",
    "Developer Workstation Builds",
    "Networking Setup",
    "Server & Storage Environment Setup",
    "Virtualization KVM/VMWare/Hyper-V",
    "Linux Setup",
    "Long-Term Service & 24/7 Support",
  ],
  MultimediaFirm: [
    "Consulting",
    "Creative Workstation Builds",
    "Server & Storage Environment Setup",
    "Hardware Calibration",
    "Long-Term Service & 24/7 Support",
  ],
  GamingCafe: [
    "Consulting",
    "Gamer Workstation Builds",
    "AIO & Custom Watercooling",
    "Headless Virtualization with Hardware Passthrough",
    "Networking Setup",
    "Server & Storage Environment Setup",
    "Hardware & Latency Testing & Optimization",
    "Long-Term Service & 24/7 Support",
  ],
};

export default servicesData;
