// src/components/ServiceInfo.js

import React from "react";
import { Typography, List, ListItem, Divider } from "@mui/material";
import servicesData from "../ServicesData";

function ServiceInfo({ userType, specificType }) {
  let servicesToDisplay = [];

  // Handle specific selections
  if (specificType in servicesData) {
    servicesToDisplay = servicesData[specificType];
  } else if (specificType === "viewAllConsumer") {
    servicesToDisplay = [
      ...servicesData.Gamer,
      ...servicesData.Developer,
      ...servicesData.Professional,
    ];
  } else if (specificType === "viewAllBusiness") {
    servicesToDisplay = [
      ...servicesData.DeveloperFirm,
      ...servicesData.MultimediaFirm,
      ...servicesData.GamingCafe,
    ];
  }

  // Remove duplicates
  const uniqueServices = Array.from(
    new Set(servicesToDisplay.map(JSON.stringify))
  ).map(JSON.parse);

  // Handle camelCase words
  const camelCaseToWords = (str) => {
    return str
      .replace(/([A-Z])/g, " $1") // Insert a space before uppercase letters
      .trim();
  };

  // Handle pluralization
  const getPlural = (word) => {
    const spacedWord = camelCaseToWords(word);
    return spacedWord.endsWith("s") ? `${spacedWord}es` : `${spacedWord}s`;
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Services for{" "}
        {getPlural(
          specificType.replace("viewAll", "").replace("Firm", " Firm")
        )}
      </Typography>
      <List>
        {uniqueServices.map((service, index) => (
          <React.Fragment key={index}>
            <ListItem>
              {service.name ? service.name : service}{" "}
              {service.price ? `- ${service.price}` : ""}
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default ServiceInfo;
