// src/components/Navbar.js
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  Container,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "#17283a", display: "flex", marginBottom: 0 }}
    >
      <Toolbar>
        <Container
          sx={{ display: "flex", position: "relative", alignItems: "center" }}
        >
          <Avatar
            alt="HorizonTech"
            src="/assets/logos/GTACustom-PC-logo.jpg"
            sx={{ width: 56, height: 56, marginX: 2 }}
            component={Link}
            to="/"
          />
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            HorizonTech
          </Typography>
        </Container>
        <Button color="inherit" component={Link} to="/">
          Home
        </Button>
        <Button color="inherit" component={Link} to="/about">
          About
        </Button>
        <Button color="inherit" component={Link} to="/services">
          Services
        </Button>
        <Button color="inherit" onClick={handleClick}>
          Shop
        </Button>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} component={Link} to="/shop">
            Prebuilds
          </MenuItem>
          <MenuItem onClick={handleClose} component={Link} to="/system-builder">
            Customize
          </MenuItem>
        </Menu>
        <Button color="inherit" component={Link} to="/contact">
          Contact
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
