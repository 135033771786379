// src/components/Footer.js

import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Box
      component="footer"
      bgcolor="#15202B"
      color="white"
      py={3}
      marginTop="auto"
    >
      <Container maxWidth="lg">
        <Typography variant="body1" align="center">
          HorizonTech © {new Date().getFullYear()}
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <a
              href="https://www.instagram.com/horizontechco/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon fontSize="large" sx={{ color: "white" }} />
            </a>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
