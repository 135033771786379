import React, { useState  } from 'react';
import { IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const imagesList = [
  'Digital-Art-PC.jpg',
  'Gaming-PC.jpg',
  'hero-image.jpg'
  // Add paths to more images as required
];
function Slideshow() {
  const [activeIndex, setActiveIndex] = useState(0);
  
    const handleNext = () => {
        setActiveIndex((prevActiveIndex) => (prevActiveIndex + 1) % imagesList.length);
    };

    const handleBack = () => {
        setActiveIndex((prevActiveIndex) => (prevActiveIndex - 1 + imagesList.length) % imagesList.length);
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '400px', overflow: 'hidden' }}>
            <img 
                src={`assets/images/${imagesList[activeIndex]}`} 
                alt="Image"
                style={{ width: '100%', height: 'auto' }}
            />
            <IconButton 
                style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
                onClick={handleBack}
            >
                <ArrowBackIos />
            </IconButton>
            <IconButton 
                style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                onClick={handleNext}
            >
                <ArrowForwardIos />
            </IconButton>
        </div>
  );
};

export default Slideshow;