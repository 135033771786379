import React from "react";
import { Container, Typography, Box, TextField, Button } from "@mui/material";

function Contact() {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Contact Us
        </Typography>

        <form>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            variant="outlined"
          />
          <TextField
            fullWidth
            margin="normal"
            label="Message"
            multiline
            rows={4}
            variant="outlined"
          />

          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{ backgroundColor: "#15202B" }}
            >
              Submit
            </Button>
          </Box>
        </form>

        <Box mt={4}>
          <Typography variant="body1">
            <strong>Email:</strong> support@horizontechpc.com
          </Typography>
          <Typography variant="body1">
            <strong>Phone:</strong> +1 (647) 462-3858
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

export default Contact;
